/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

export const ImpressumPageTemplate = ({ html }) => {
  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>Webdesign Agentur ignis | Impressum</title>
        <meta name="description" content="Impressumseite der Webdesign Agentur ignis" />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Webdesign Agentur ignis | Impressum" />
        <meta property="og:url" content="/impressum" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/agentur-ignis-titelbild.jpg`}
        />
      </Helmet>
      <section sx={{ py: 8, px: 4 }}>
        <div
          sx={{
            maxWidth: "1000px",
            margin: "0 auto",
            h1: {
              fontSize: [7, 7, 7],
              mt: "0px",
              mb: [3, 4, 4],
              span: {
                display: ["inline", "none", "none"],
              },
            },
            h2: {
              variant: "text.body",
              fontSize: [4, 5, 5],
              fontWeight: "semibold",
              mb: 1,
            },
            h3: {
              variant: "text.body",
              fontSize: [3, 5, 5],
              fontWeight: "semibold",
              mb: 1,
              mt: 3,
            },
            h4: {
              variant: "text.body",
              fontSize: [2, 4, 4],
              fontWeight: "semibold",
              mb: 1,
              mt: 3,
            },
            p: {
              mb: 1,
              mt: "0px",
              fontSize: [2, 4, 4],
              a: { textDecorationColor: "one", color: "one" },
            },
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
    </React.Fragment>
  );
};
